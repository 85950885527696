import React from "react";

import { makeStyles } from "@mui/styles";
import { Configuracao } from "../../configuracao";

const useStyles = makeStyles(theme => ({
	contactsHeader: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		flexDirection: "row",
		padding: "5px 6px 5px 6px",		
		height: "67px",
		background: Configuracao.COR_PRIMARY,
		color: Configuracao.COR_SECUNDARY
	},
}));

const MainHeader = ({ children }) => {
	const classes = useStyles();

	return <div className={classes.contactsHeader}>{children}</div>;
};

export default MainHeader;
