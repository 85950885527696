import React, { useEffect, useState } from "react";
import { Button, Dialog, Container, IconButton, useMediaQuery } from "@mui/material";
import "./style.css"
import { toast } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';
import BackspaceIcon from '@mui/icons-material/Backspace';

const InformarQtdPreco = ({ open, onClose, faltaPagar, valorDesconto, title, onQtdPrecoSelecionado, operacao }) => {
  
  const [qtdPreco, setQtdPreco] = useState(faltaPagar);
  const [count, setCount] = useState(0);  

  const isSmallScreen = useMediaQuery('(max-width:825px)'); 

  useEffect(() => { 
    const arrayOperacao = ['QUANTIDADE', 'PRECO', 'DESCONTO'];       

    if (!arrayOperacao.includes(operacao)) {      
      toast.error("A operação está incorreta");        
    } else {
      if (operacao === "QUANTIDADE") {  
        Number.isInteger(faltaPagar) ? setQtdPreco(faltaPagar.toFixed(0)) : setQtdPreco(faltaPagar.toLocaleString('pt-BR', { minimumFractionDigits: 4 }));
      } 
      else if (operacao === "PRECO") {
        setQtdPreco(faltaPagar.toLocaleString('pt-BR', { minimumFractionDigits: 2 }));
      }
      else if (operacao === "DESCONTO") {
        setQtdPreco(valorDesconto.toLocaleString('pt-BR', { minimumFractionDigits: 2 }));
      }
    }
  }, [faltaPagar, open, operacao]);


  useEffect(() => {
    if (!open) {      
      setCount(0);
    }
  }, [open]);

  const handleClose = () => {             
    setTimeout(() => {
      onClose();     
    },200)    
  };

  const handleConfirmar = () => {    
    const valor = operacao === "QUANTIDADE" ? parseFloat(qtdPreco.replace(",", ".")) : parseFloat(qtdPreco.replace(/\./g, '').replace(',', '.')) ;
    const precoQuantidade = operacao === "QUANTIDADE" ? " uma quantidade " : " um valor ";  

    if (operacao === "DESCONTO") {
      if (valor >= (faltaPagar + valorDesconto)) {
        toast.warning("O valor desconto maior do que falta para pagar");    
        return; 
      }
    }    

    if ((isNaN(valor) || valor <= 0) && operacao !== "DESCONTO") {
      toast.warning(`Informe ${precoQuantidade} válido`);
    } else {
      if (operacao === "PRECO") {        
        if (valor > faltaPagar && title !== "Dinheiro") {
          return toast.warning(`Pagamento em ${title} superior ao que falta à pagar`);
        } 
      };
      
      handleClose();      
      
      setTimeout(()=> {
        onQtdPrecoSelecionado(valor);       
      },300)
    }
  };
  
  const handleNumber = (firstTime, currentPrice, number) => {
    let resetPrice

    if (operacao === "QUANTIDADE") {      
      resetPrice = Number.isInteger(qtdPreco) ?  ['0'] : ['0', '0', '0'];
    } 
    else {
       resetPrice = operacao === "QUANTIDADE" ? ['0', '0', '0', '0', '0'] : ['0', '0', '0']
    }


    const temp = firstTime
        ? resetPrice
        : currentPrice.replace(',', '').split('');

    temp.push(number);

    if (operacao === "QUANTIDADE") {
      
      if (!qtdPreco.includes(",")) {
        temp.splice(temp.length , 0, '');
      } else  {
        temp.splice(temp.length - 3, 0, ',');
      }

    } else {
      temp.splice(temp.length - 2, 0, ',');
    }

    const tempLength = temp.length;       
    
    let temp2;

    if (operacao === "QUANTIDADE") {         
      temp2 = temp.splice(0, tempLength - 7)
    } 
    else {
      temp2 = temp.splice(0, tempLength - 5)
    }    

    const temp3 = temp.slice();

    let foundNotZeroNumber = false;
    for (let index = 0; index < temp2.length; index++) {
        if (foundNotZeroNumber) break;

        if (temp2[index] === '0') temp2.splice(index, 1);
        else foundNotZeroNumber = true;
    }

    const temp4 = [];
    temp4.push(...temp2)
    temp4.push(...temp3)

    return temp4.join('');
  };

  const formatPriceVisualization = (price) => {    
    const priceString = price.toString();
  
    if (priceString.includes(",")) {      
      const priceArray = priceString.split('');
      if (priceArray.length > 4 && priceArray[0] === '0') priceArray.splice(0, 1);
      return priceArray.join('');
    } 
    else {      
      return parseInt(priceString);
    }
  };  

  const adicionarNumero = (numero) => {
    let novoQtdPreco = qtdPreco;
    
    if (novoQtdPreco.indexOf(',') === -1 || numero !== ',') {
      setCount(count + 1);
      let teste = 0;
      
      if (count > 0) {
        teste = handleNumber(false, novoQtdPreco, numero);
      } else {
        teste = handleNumber(true, novoQtdPreco, numero);
      }
      
      setQtdPreco(teste);
    }
  };

  const apagarUltimoDigito = () => {
    if (count > 0) {      
      setQtdPreco(qtdPreco.slice(0, -1));
      setCount(count - 1);
    } else {      
      setQtdPreco((parseFloat(qtdPreco) / 10).toFixed(2));
    }
  };

  return (
    <Dialog
        open={open}      
        fullWidth={true}
        maxHeight={true}          
        PaperProps={ {
          style: {
            borderRadius: 10,
            height: isSmallScreen ? "70%" : "35em",
            width: isSmallScreen ? "100%" : "30%",
            maxWidth: "50vh",
            minWidth: isSmallScreen ? "350px" : "500px"
          },
        }}
        onKeyDown={(event) => {
          if (open) {
            const allowedKeys = [
              '0', '1', '2', '3', '4', '5', '6',
              '7', '8', '9', 'Backspace', isSmallScreen && ',', 'Enter'
            ];

            if (allowedKeys.includes(event.key)) {
              if (event.key === '0') document.getElementById('zeroKey').click();
              if (event.key === '1') document.getElementById('oneKey').click();
              if (event.key === '2') document.getElementById('twoKey').click();
              if (event.key === '3') document.getElementById('threeKey').click();
              if (event.key === '4') document.getElementById('fourKey').click();
              if (event.key === '5') document.getElementById('fiveKey').click();
              if (event.key === '6') document.getElementById('sixKey').click();
              if (event.key === '7') document.getElementById('sevenKey').click();
              if (event.key === '8') document.getElementById('eightKey').click();
              if (event.key === '9') document.getElementById('nineKey').click();
              if (event.key === 'Backspace') document.getElementById('backspaceKey').click();
              if (event.key === ',') document.getElementById('virgulaKey').click();
              if (event.key === 'Enter') {
                document.getElementById('enterKey').click();                
              }
            }
          }
        }}
    >
      <Container class="close"> 
        <IconButton onClick={() => (handleClose())}> 
          <CloseIcon/>
          </IconButton>
      </Container>

      <Container class="container">
        <Container class="title">  
          {title} 
        </Container>

        <Container class="informarQtdPreco">           
          <div class="digitado">    
            {operacao === "PRECO" || operacao === "DESCONTO" ? (  
              <>                
                R$&nbsp;{formatPriceVisualization(qtdPreco)}
              </>
            ) : 
            (
              formatPriceVisualization(qtdPreco)
            )}    
          </div>
        </Container>        

        <Container class="calculadoraInformaPreco">
          <Container class="numeros">          
            <Button tabIndex={"-1"} id="oneKey" disableRipple class="buttonCalculadora" onClick={() => adicionarNumero(1)}>1</Button>
            <Button tabIndex={"-1"} id="twoKey" disableRipple class="buttonCalculadora" onClick={() => adicionarNumero(2)}>2</Button>
            <Button tabIndex={"-1"} id="threeKey" disableRipple class="buttonCalculadora" onClick={() => adicionarNumero(3)}>3</Button>
            <Button tabIndex={"-1"} id="fourKey" disableRipple class="buttonCalculadora" onClick={() => adicionarNumero(4)}>4</Button>
            <Button tabIndex={"-1"} id="fiveKey" disableRipple class="buttonCalculadora" onClick={() => adicionarNumero(5)}>5</Button>
            <Button tabIndex={"-1"} id="sixKey" disableRipple class="buttonCalculadora" onClick={() => adicionarNumero(6)}>6</Button>
            <Button tabIndex={"-1"} id="sevenKey" disableRipple class="buttonCalculadora" onClick={() => adicionarNumero(7)}>7</Button>
            <Button tabIndex={"-1"} id="eightKey" disableRipple class="buttonCalculadora" onClick={() => adicionarNumero(8)}>8</Button>
            <Button tabIndex={"-1"} id="nineKey" disableRipple class="buttonCalculadora" onClick={() => adicionarNumero(9)}>9</Button>
            
            {operacao === "QUANTIDADE" ? (
                <Button tabIndex={"-1"} id="virgulaKey" disableRipple class="buttonCalculadora" onClick={() => adicionarNumero(",")}>,</Button>
              ): 
              (
                <Button tabIndex={"-1"} disableRipple 
                  class="buttonCalculadora" 
                  onClick={() => { 
                    setQtdPreco("0.00");
                    setCount(0);
                  }}
                >C</Button>
              )            
            }
            
            <Button tabIndex={"-1"} id="zeroKey" disableRipple class="buttonCalculadora" onClick={() => adicionarNumero(0)}>0</Button>            
            <Button tabIndex={"-1"} id="backspaceKey" disableRipple class="buttonCalculadora" onClick={() => apagarUltimoDigito()}>{<BackspaceIcon color="action" disableRipple/>}</Button>         
         </Container>
        </Container>

        <Container class="button">                 
          <Button id="enterKey" 
            color="primary"
            variant="contained"    
            onClick={handleConfirmar}                              
            style={{height: 45, width: "95%", borderRadius: 10, textTransform: "none"}}         
          >
            CONFIRMAR
          </Button>              
        </Container>
        
      </Container>
    </Dialog>

  );
};

export default InformarQtdPreco;
