import React, { useState } from "react";
import { NumericFormat } from "react-number-format";
import { TextField, useMediaQuery } from "@mui/material";
import { Field } from "formik";

const FlexNumericField = ({
  label,
  name,
  width,
  inputMode = "text",
  autoFocus = false,
  error,
  helperText,
  disabled = false,
  decimalScale = 2,
  allowNegative = false,
  fixedDecimalScale = true,
  prefix = "",
  thousandSaparator = "",
}) => {
  const isSmallScreen = useMediaQuery("(max-width:825px)");

  return (
    <Field
      as={NumericFormat}
      customInput={TextField}
      decimalScale={decimalScale}
      allowNegative={allowNegative}
      allowLoadingZeros={false}
      fixedDecimalScale={fixedDecimalScale}
      decimalSeparator=","
      allowDecimal={["."]}
      prefix={prefix}
      thousandSaparator={thousandSaparator}
      variant="outlined"
      margin="dense"
      fullWidth={isSmallScreen}
      autoFocus={autoFocus}
      label={label}
      name={name}
      style={{ width: isSmallScreen ? undefined : width }}
      inputProps={{ inputMode: inputMode }}
      error={error}
      helperText={helperText}
      disabled={disabled}
    />
  );
};

export default FlexNumericField;
