import React from "react";

import { Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Input
} from "@mui/material";  

import "./style.css";

const InputDialogModal = ({ title, description, open, onClose }) => {
	return (
		<Dialog
			open={open}
			onClose={() => {onClose("", "")}}
		>
			<DialogTitle >{title}</DialogTitle>
			<DialogContent dividers>
				{description}
				<br/>
				<Input
				id="input.dialog.modal"
				/>
			</DialogContent>
			<DialogActions>
			
			<Button onClick={() => {const answer = document.getElementById("input.dialog.modal").value; onClose("Confirmar", answer)}}>{"Confirmar"}</Button>
			<Button onClick={() => onClose("Sair", "")}>{"Sair"}</Button>
			</DialogActions>
		</Dialog>
		
	);
};

export default InputDialogModal;
