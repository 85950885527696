function validarCpfCnpj(numero) {

  let numeroConferido = numero;
  let soma;
  let digito;
  let k;
  let cnpj;
  
  if (numero.length === 11) {
      numero = numero.substr(0, 9);
      cnpj = false;
  } else if (numero.length === 14) {
      numero = numero.substr(0, 12);
      cnpj = true;
  } else {
      return  false;
  }
  
  for (let j = 0; j < 2; j++) {
      k = 2;
      soma = 0;
      for (let i = numero.length - 1; i >= 0; i--) {
          soma += (parseInt(numero[i], 10) * k);
          k++;
          if (k > 9 && cnpj) {
              k = 2;
          }
      }
      digito = 11 - (soma % 11);
      if (digito >= 10) {
          digito = 0;
      }
      
      numero += digito.toString();
  }    
  
  return numeroConferido === numero;
}

export {validarCpfCnpj};