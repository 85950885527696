import React from "react";

import { Button, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MainContainer from "../../components/MainContainer";
import logoIMG from "../../assets/notaCertaBlack.png"

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: "5%"
  },
  logo: {
    width: "350px",
    height: "150px",
    paddingBottom: "50px",
  },
  title: {
    fontSize: "100px",
    color: "black"
  },
  message: {
    fontSize: "20px",
    color: "black",
    paddingBottom: "50px"
  },
}));

const Page404 = () => {
  const classes = useStyles();

  return (
    <MainContainer>
      <Container className={classes.container}>
        <div className={classes.container}>
          <img src={logoIMG} className={classes.logo} alt="NotaCerta" />
          <span className={classes.title}><strong>404</strong></span><br/>
          <span className={classes.message}>Oops... Página não encontrada!</span><br/><br/>
          <Button
              variant="contained"
              color="primary"
              style={{ marginRight: "1em" }}
              href={"/"}
            >
              Voltar para o Ínicio
            </Button>
        </div>
      </Container>
    </MainContainer>
  );
};

export default Page404;