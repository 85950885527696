export const gerarDescontoItens = (arrayItens, valorDesconto, nomeCampo) => {
  // verificarCampos(arrayItens, valorDesconto, nomeCampo)

        const itens = arrayItens
        let totalItens = itens.length
        let contadorItens = 0
        let valorTotalDescontoItens = 0

        itens.forEach(item => {
          contadorItens++

          let valorDescontoItem = Number((valorDesconto / totalItens).toFixed(2));        
          valorTotalDescontoItens = valorTotalDescontoItens + valorDescontoItem

          if (totalItens === contadorItens) {
            /* Se for o ultimo iten, ajustar o valor do ultimo item caso os descontos do itens nao for o mesmo do valorDesconto */
            if (valorTotalDescontoItens > valorDesconto) {            
              const diferenca = Number((valorTotalDescontoItens - valorDesconto).toFixed(2))
              valorTotalDescontoItens = valorTotalDescontoItens - diferenca
              valorDescontoItem = valorDescontoItem - diferenca

            } 
            else if (valorTotalDescontoItens < valorDesconto) {            
              const diferenca = Number((valorDesconto - valorTotalDescontoItens).toFixed(2))
              valorTotalDescontoItens = valorTotalDescontoItens + diferenca
              valorDescontoItem = valorDescontoItem + diferenca
            }
          }

          item[nomeCampo] = valorDescontoItem
        })
        
  return itens
}


const verificarCampos = (arrayItens, valorDesconto, nomeCampo) => {
  if (arrayItens.length === 0) {
    throw new Error("Não foram informados itens para aplicar o desconto.");
  }
  else if (valorDesconto <= 0) {
    throw new Error("Informe um valor de desconto válido para ser rateado nos itens.");
  }
  else if (!nomeCampo) {
    throw new Error("O nome do campo não foi informado.");
  }
};
