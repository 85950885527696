import React from "react";
import { Field } from "formik";
import { TextField, useMediaQuery } from "@mui/material";

const FlexFileField = ({ label, name, width = "10%", setFieldValue, accept = ".pfx" }) => {
  const isSmallScreen = useMediaQuery("(max-width:825px)");

  const handleChange = (event) => {
    setFieldValue(name, event.currentTarget.files[0]);
  };

  return (
    <Field
      as={TextField}
      label={label}
      name={name}
      variant="outlined"
      margin="dense"
      type="file"
      value={undefined}
      fullWidth={isSmallScreen}
      style={{
        width: isSmallScreen ? undefined : width,
      }}
      inputProps={{ accept, style: { cursor: "pointer" } }}
      InputLabelProps={{ shrink: true }}
      onChange={handleChange}
    />
  );
};

export default FlexFileField;
