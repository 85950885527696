import React, { useState, useEffect } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { Button,
  TextField,
  Dialog,
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  CircularProgress,
  FormControl } 
from "@mui/material";

import { makeStyles } from "@mui/styles";
import { green } from '@mui/material/colors';
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
	floatingButton: {
		transition: 'transform 0.3s',
	
		'&:hover': {
			transform: 'translateY(-5px)',
		},
	},
}));

const EmbalagemSchema = Yup.object().shape({
	descricao: Yup.string()
		.min(2, "Muito curto!")
		.max(50, "Muito Longo!")
		.required("Obrigatório"),
  
  unidade: Yup.string()
		.min(2, "Muito curto!")
		.max(6, "Muito Longo!")
		.required("Obrigatório"),
  
  fator: Yup.string()
		.min(1, "Muito curto!")
    .max(6, "Muito Longo!")
		.required("Obrigatório")
});

const EmbalagemModal = ({ open, onClose, embalagemId }) => {
	const classes = useStyles();

	const initialState = {
		id: "",
		descricao: "",  
    unidade: "",  
    fator: "", 
	};

  const [embalagem, setEmbalagem] = useState(initialState);
  const [unidade, setUnidade] = useState('');
  

  useEffect(() => {
		(async () => {            
			if (!embalagemId) return;
			try {
				const { data } = await api.get(`/embalagem/${embalagemId}`);
                
				setEmbalagem(prevState => {
					return { ...prevState, ...data };
				});				
				
			} catch (err) {
				toastError(err);
			}
		})();

		return () => {
			setEmbalagem({
				id: "",
        descricao: "",    
        unidade: "", 
        fator: "",         
			});
		};
	}, [embalagemId, open]);

  const handleUnidadeChange = (event) => {
    const inputValue = event.target.value;
    const uppercaseValue = inputValue.toUpperCase();
    setUnidade(uppercaseValue);
  };

  const handleClose = () => {
		onClose();
		setEmbalagem(initialState);
	};

  const handleSaveEmbalagem = async values => {
		const embalagemData = { ...values };
		try {      
			if (embalagemId) {
				await api.put(`/embalagem/${embalagemId}`, embalagemData);
			} else {                
				await api.post("/embalagem", embalagemData);
			}
			toast.success("Embalagem salva com sucesso!");
			handleClose();
		} catch (err) {
			toastError(err);
		}
	};

  return (
    <div className={classes.root}>
	  <Dialog open={open} onClose={handleClose} scroll="paper">
        <DialogTitle>
            {embalagemId
              ? "Alterar embalagem"
              : "Adicionar embalagem"}
        </DialogTitle>
          <Formik
            initialValues={embalagem}
            enableReinitialize={true}
            validationSchema={EmbalagemSchema}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                handleSaveEmbalagem(values);
                actions.setSubmitting(false);
              }, 400);
            }}
          >
            {({ touched, errors, isSubmitting }) => (
              <Form>
                <DialogContent dividers>
                  <Field
                    as={TextField}
                    label={"Código"}
                    autoFocus
                    name="id"
                    error={touched.id && Boolean(errors.id)}
                    helperText={touched.id && errors.id}
                    variant="outlined"
                    disabled
                    margin="dense"
                    className={classes.textField}
                  />               

                  <Field
                    as={TextField}
                    label={"Descrição"}
                    name="descricao"
                    autoFocus={"true"}
                    error={touched.descricao && Boolean(errors.descricao)}
                    helperText={touched.descricao && errors.descricao}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
				          />   

                  <Field                    
                    as={TextField}
                    label={"Unidade"}
                    name="unidade"  
                    //value={unidade}                  
                    //onChange={handleUnidadeChange}
                    error={touched.unidade && Boolean(errors.unidade)}
                    helperText={touched.unidade && errors.unidade}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}                     
                    
				          />   
                  <Field
                    type="number"
                    as={TextField}
                    label={"Fator"}
                    name="fator"
                    error={touched.fator && Boolean(errors.fator)}
                    helperText={touched.unidade && errors.fator}
                    variant="outlined"
                    margin="dense"
                    className={classes.TextFieldsRounded}
				          />                    

                  <br />  

                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    margin="dense"
                  >                    
                  </FormControl>

                </DialogContent>   

                <DialogActions>
                  <Button
                    onClick={handleClose}
                    //color="inherit"
                    color="secondary"
                    disabled={isSubmitting}
                    variant="outlined"
					          className={classes.floatingButton}
                  >
                    {"cancelar"}
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={isSubmitting}
                    variant="contained"
                    className={`${classes.btnWrapper} ${classes.floatingButton}`}
                  >
                    {embalagemId
                      ? `${"Salvar"}`
                      : `${"Adicionar"}`}
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
      </Dialog>
    </div>
  );
};

export default EmbalagemModal;