import React, { useEffect, useState } from "react";
import { PatternFormat } from "react-number-format";
import { TextField, useMediaQuery } from "@mui/material";
import { useField, useFormikContext } from "formik";

const FlexFormatField = ({
  label,
  name,
  width,
  inputMode = "text",
  autoFocus = false,
  error,
  helperText,
  disabled = false,
  format,
  mask = "_",
  allowEmptyFormatting = true,
  onChangeEvent = null
}) => {
  const isSmallScreen = useMediaQuery("(max-width:825px)");
  const { setFieldValue, values } = useFormikContext();
  const [field] = useField(name); 

  const getNestedValue = (path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], values);
  };

  const [localValue, setLocalValue] = useState(field.value || '');

  useEffect(() => {
    const previousValue = getNestedValue(name);

    if (localValue !== previousValue) {
      setLocalValue(getNestedValue(name) || '');
    }
  }, [values]);

  return (
    <PatternFormat
      customInput={TextField}
      allowEmptyFormatting = {allowEmptyFormatting}
      format={format}
      placeholder={format.replaceAll('#', '_')}
      mask={mask}
      variant="outlined"
      margin="dense"
      fullWidth={isSmallScreen}
      autoFocus={autoFocus}
      label={label}
      name={name}
      style={{ width: isSmallScreen ? undefined : width }}
      inputProps={{ inputMode: inputMode }}
      error={error}
      helperText={helperText}
      disabled={disabled}
      value={localValue}
      onValueChange={({ value }) => {
        if (onChangeEvent) onChangeEvent(value)
        setLocalValue(value);
        setFieldValue(name, value)
      }}
    />
  );
};

export default FlexFormatField;
