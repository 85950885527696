import React from "react";
import { Container, Skeleton } from "@mui/material";
import './style.css';

const CardSkeleton = ({ columns }) => {  
  return (
    <>
			{Array.from({ length: columns }, (_, index) => (					
				<div class="div">
						<Container class="containerIcon"> 						
							 <Skeleton style={{borderRadius: "7px"}} animation="wave" variant="rect" height={50} width={50}/> 
						</Container>

						<Container class="flex">
							<Skeleton animation="wave" height={50} width={150} /> 
						</Container>	
						
						<Container class="flex">  
							<Skeleton animation="wave" height={30} width={150} /> 
						</Container>
						
						<Container class="flex">
							<Skeleton animation="wave" height={25} width={130} /> 
						</Container>
				</div>
			))}
    </>
  );
};

export default CardSkeleton;
