import api from "../../../../services/api";

export const buscarProduto = async (produtoId) => {
	try {
		const produto = await api.get(`/v1/produtos/${produtoId}`)
		
		return produto		
	} catch (error) {		
    throw error;		
	}
};