import api from "../../../../services/api";

export const buscarCliente = async(dest) => {
	let cliente

	try {
		let clienteResponse	
		clienteResponse = await api.get(`/v1/clientes/${dest.clienteId}`)		
		cliente = clienteResponse.data
	} catch (error) {		
    throw error; 		
	}	

  return cliente ? {...dest, 
	clienteId: cliente.id,
	inscricao: cliente.inscricao,   
	nome: cliente.nome,
	inscricaoEstadual: cliente.inscricaoEstadual,
	email: cliente.email,
	endereco: {
		logradouro: cliente.logradouro,
		numero: cliente.numero,
		complemento: cliente.complemento,
		bairro: cliente.bairro,				
		codigoIbge: cliente.municipio.codigoIbge,
		municipio: cliente.municipio.nome,
		uf: cliente.municipio.estado.uf,
		cep: cliente.cep,
		fone: cliente.fone
	}} : dest;
};


