import { toast } from "react-toastify";
import { i18n } from "../translate/i18n";

const toastError = err => {

	const handleProblemaGeral = () => {
		toast.error("Não foi possível fazer isto no momento, tente novamente mais tarde!");
	}

	if (err?.response) {
		const errorMsg = err?.response?.data?.message || err?.response.data.error || err?.response;
		if (errorMsg) {
			if (i18n.exists(`backendErrors.${errorMsg}`)) {
				toast.error(i18n.t(`backendErrors.${errorMsg}`), {
					toastId: errorMsg,
				});
			} else {
				toast.error(errorMsg, {
					toastId: errorMsg,
				});
			}
		} else {
			handleProblemaGeral();
		} 
	} else {
		handleProblemaGeral();
	}
	
};

export default toastError;