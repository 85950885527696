import api from "../../../../services/api";

export const calcularImpostoAproximado = async (codigo, vProd, origem) => {
  let impostoAproximadoResponde = await api.get(`/v1/ncm-imposto-aproximado/${codigo}`)
  let impostoAproximado = impostoAproximadoResponde.data
    
  let impostoNacional = 0
  let impostoImportado = 0
  let impostoEstadual = 0
  let impostoMunicipal = 0
  let valorTotalImpostoAproximado = 0

  if (origem === 0) {
    if (impostoAproximado.aliquotaNacional > 0) {      
      impostoNacional = parseFloat((vProd * impostoAproximado.aliquotaNacional / 100).toFixed(2));      
    }
  } 
  else if (origem === 1) {
    if (impostoAproximado.aliquotaImportada > 0) {
      impostoImportado = parseFloat((vProd * impostoAproximado.aliquotaImportada / 100).toFixed(2))
    }
  }
  
  if (impostoAproximado.aliquotaEstadual > 0) {
    impostoEstadual = parseFloat((vProd * impostoAproximado.aliquotaEstadual / 100).toFixed(2))
  }
  
  if (impostoAproximado.aliquotaMunicipal > 0) {
    impostoMunicipal = parseFloat((vProd * impostoAproximado.aliquotaMunicipal / 100).toFixed(2))
  }

  valorTotalImpostoAproximado = parseFloat((impostoNacional + impostoImportado + impostoEstadual + impostoMunicipal).toFixed(2))
  
  return {    
    impostoNacional,
    impostoImportado,
    impostoEstadual,
    impostoMunicipal,
    valorTotalImpostoAproximado
  }
}