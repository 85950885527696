import React from "react";

import { Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle
} from "@mui/material";  

import Typography from '@mui/material/Typography';

const ConfirmationModal = ({ title, children, open, onClose, onConfirm }) => {
	return (
		<Dialog
			open={open}
			onClose={() => onClose(false)}
			aria-labelledby="confirm-dialog"
		>
			<DialogTitle id="confirm-dialog">{title}</DialogTitle>
			<DialogContent dividers>
				<Typography>{children}</Typography>
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					onClick={() => onClose(false)}
					color="default"
				>
					{"Cancelar"}
				</Button>
				<Button
					variant="contained"
					onClick={() => {
						onClose(false);
						onConfirm();
					}}
					color="secondary"
				>
					{"Confirmar"}
				</Button>
			</DialogActions>
		</Dialog>
		
	);
};

export default ConfirmationModal;
