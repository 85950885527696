import React, { useEffect } from "react";

import { Button,
	Dialog,
	DialogActions,
	DialogContent,
	Grid,
	Input,
	Slider,
	TextField,
} from "@mui/material";  

import "./style.css";
import { Box } from "@mui/system";
import InformarCliente from "../InformarCliente";
import { geraJsonNota } from "../../utils/funcoes/geraJsonNota/geraJsonNota";
import DialogModal from "../DialogModal";
import { toast } from "react-toastify";
import api from "../../services/api";

const DevolucaoItensModal = ({ isSmallScreen, nota, open, onClose, onAtualizar }) => {
	/* To-Do: implementar edicao de quantiade de itens */

	const [valorItens, setValorItens] = React.useState([]);
	const [informarCliente, setInformarCliente] =  React.useState(false);
	const [cliente, setCliente] =  React.useState(null); 
	const [dialogModal, setDialogModal] = React.useState({open: false, keyword: "", title: "", description: "", buttons: [], result: ""});

	useEffect(() => {
		const buscarValorItens = async () => {
			if (nota) {
				let valorItensList = [];
				for (const item of nota?.det) {
					valorItensList = [...valorItensList, item.prod.qCom]
				}
				setValorItens([...valorItensList])
			}	
		};
		buscarValorItens();
	}, [nota]);

	const handleChange = (index, value) => {
        /*setValorItens((prev) => {
            const updated = [...prev]; // Cria uma cópia do array
            updated[index] = value; // Atualiza o valor pelo índice
            return updated;
        });*/
    };

	useEffect(() => {
		if (cliente && cliente?.id > 0) {      
			prosseguirDevolucaoItens();    
		}
	  }, [cliente])

	const handleCloseModalInformarCliente = (clienteValue) => {    
		setInformarCliente(false);  
		setCliente(clienteValue ? clienteValue : null);
	}

	const confirmarDevolucaoItens = () => {
		if (nota) {
			if (nota?.ide?.mod === "65") {
				setInformarCliente(true);
			} else {
				prosseguirDevolucaoItens();
			}
				
		}
	}

	const prosseguirDevolucaoItens = async () => {	
		try {
			/* To-Do: fazer considerar a mudanca de itens que tiver, no caso a quantidade */
			const notaDevolucao = {...nota, dest: nota?.ide?.mod === "65" && cliente ? {clienteId: cliente.id} : nota.dest}
			const jsonNota = await geraJsonNota(notaDevolucao, 'DEVOLVER')

			if (jsonNota.status === 200) {
				const envio = await api.post(`/v1/notas/`, jsonNota.nota);
				
				if (envio.data.cStat === '100') {
					toast.success(`Devolução realizada com sucesso! Chave: ${envio.data.chave}`);
				} else {                        
					setDialogModal({...dialogModal, keyword: "info", 
									open: true, 
									title: "Não foi possivel realizar a devolução!", 
									description: `${envio.data.mensagem}`, 
									buttons: ["OK"]});
				} 
				onAtualizar(envio.data)
			} 
			else {
				setDialogModal({...dialogModal, keyword: "info", 
								open: true, 
								title: "Não foi possivel realizar a devolução!", 
								description: `${jsonNota?.message}`, 
								buttons: ["OK"]});
			}
		} catch (error) { 			
			const errorMsg = error.response?.data?.message || error.response.data.error;       
			setDialogModal({...dialogModal, keyword: "info", 
							open: true, 
							title: "Não foi possivel realizar a devolução!", 
							description: `${errorMsg}`, 
							buttons: ["OK"]});
		}
		
		onClose();
	}

	const handleCloseDialogModal = (result) => {
		setDialogModal({...dialogModal, open: false, result: result});
	};

	return (
		<>
			<InformarCliente
				open={informarCliente}
				onClose={handleCloseModalInformarCliente}
			/> 
			<DialogModal
				title={dialogModal.title}
				open={dialogModal.open}
				onClose={handleCloseDialogModal}  
				description={dialogModal.description}   
				buttons={dialogModal.buttons}      
				>				
			</DialogModal>
			<Dialog
				open={open}
				onClose={() => onClose(false)}
				fullscreen={true}
			>
				<DialogContent dividers style={{backgroundColor: "white", fontSize: isSmallScreen ? "10px" : ""}}>
					<div style={{border: "solid 1px black", padding: "30px", paddingTop: "5px"}}>
						<table width={"100%"}><th align={"center"}><span>ITENS</span></th></table>
						<table width={"100%"}>
						<tr align={"left"}>
							<th>#</th>
							<th>Cód.</th>
							<th>Descrição</th>
							<th>Valor Unitário</th>
							<th>Qtd.</th>
						</tr>
						{nota?.det.map((array, index) => (
							<tr align={"right"}>
							<td>{index+1}</td>
							<td align={"center"}>{array.prod.cProd}</td>
							<td align={"left"}>{array.prod.xProd}</td>
							<td>{parseFloat(array.prod.vUnCom).toFixed(2).replace(".", ",")}</td>
							<td>
								<Box sx={{ width: 50 }}>
									<Grid container spacing={2} sx={{ alignItems: 'center' }}>
										<Grid item>
										<input
											style={{width: 50}}
											type="number"
											value={array.prod.qCom}
											min={0}
											max={array.prod.qCom}
											onChange={(e) => handleChange(index, Number(e.target.value))}
										/>
										</Grid>
									</Grid>
								</Box>
							</td>
							</tr>))}	
						</table>

						<table width={"100%"}>
							<tr><th align={"left"}><span><strong>VALOR TOTAL R$</strong></span></th><th align={"right"}> <span><strong>{parseFloat(nota?.icmsTot.vNF).toFixed(2).replace(".", ",")}</strong></span><br/></th></tr>
						</table>
						<hr /><br/>
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => confirmarDevolucaoItens()}>{"Continuar"}</Button>
					<Button onClick={() => onClose(false)}>{"Cancelar"}</Button>
				</DialogActions>
			</Dialog>
		</>
		
	);
};

export default DevolucaoItensModal;
