export const gerarEmit = (operacaoOpcao, emitente, idEmpresaEmitente, idEmpresaEnderecoEmitente) => {

  	/* 1-Simples / 2-Simples sublimite / 3-Regime Normal / 4-Mei */
  	const CRT = emitente.regimeTributario.apuracaoImposto === "MEI" ? "4" : "1";

	const emit = { 
		id: operacaoOpcao.alterar ? idEmpresaEmitente : undefined,
		CNPJ: emitente.cnpj,
		xNome: emitente.razaoSocial,
		xFant: emitente.nomeFantasia,
		enderEmit: {
				id: operacaoOpcao.alterar ? idEmpresaEnderecoEmitente : undefined,
				xLgr: emitente.endereco.logradouro,
				nro: emitente.endereco.numero,
				xCpl: emitente.endereco.complemento,
				xBairro: emitente.endereco.bairro,				
				
				cMun: emitente.endereco.municipio.codigoIbge,
				xMun: emitente.endereco.municipio.nome,
				UF: emitente.endereco.municipio.estado.uf,
				
				CEP: emitente.endereco.cep,
				cPais: "1058",
				xPais: "Brasil",
				fone: undefined
		},
		IE: emitente.inscricaoEstadual,    
		IM: undefined,      
		CRT,
		iEST: undefined,
		CNAE: emitente.regimeTributario.cnaePrincipal,		
		IEST: undefined
  }	

  return emit
}