export const gerarCfoEntrada = (csosn, ide, dest) => {
  /* Operacao
  01-Revenda de mercadoria. (Comércio)"
  02-Revenda de mercadoria efetuada fora do estabelecimento. (Comércio)"
  03-Venda de produção própria (Indústria ou equiparada)"
  04 Venda de produção própria efetuada fora do estabelecimento. (Indústria ou equiparada)*/

  /*CFO
  5101 - 1201
  5102 - 1202
  5103 - 1201
  5104 - 1202
  5402 - 1410
  5405 - 1411

  6101 - 2201
  6102 - 2202
  6103 - 2201
  6104 - 2202
  6108 - 2202
  6402 - 2410
  6404 - 2411*/
  
  const primeiroDigito = ide.idDest === "2" ? "2" : "1"; /* 2000/1000 */

  let cfo = "202" /* 1202/2202 */

  if (ide.operacao === "01") {    
    cfo = csosn === "500" ? (primeiroDigito === "1" ? "411" : "411") : (dest && dest?.indIEDest === "9" && primeiroDigito === "2" ? "202" : "202") /* 1411/2411 ou 1202/2202 */
  }
  else if (ide.operacao === "02") {    
    cfo = csosn === "500" ? (primeiroDigito === "1" ? "411" : "411") : "202" /* 1411/2411 ou 1202/2202 */
  }
  else if (ide.operacao === "03") {    
    cfo = "201" /* 1201/2201 */
  }
  else if (ide.operacao === "04") {    
    cfo = "201" /* 1201/2201 */
  }

  return primeiroDigito + cfo
}