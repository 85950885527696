import React, { useEffect, useState } from "react";
import { Button, Dialog, Container, useMediaQuery, DialogContent, TextField, Divider, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, DialogTitle } from "@mui/material";
import "./style.css"
import MainHeaderFX from "../MainHeaderFx";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { formatarCnpjCpf } from "../../utils/formatacao/cnpjCpf.js";
import ClienteModal from "../ClienteModal/index.js";
import DialogModal from "../DialogModal/index.js";

const InformarCliente = ({ open, onClose }) => {  
  const isSmallScreen = useMediaQuery('(max-width:825px)');  
  const [searchValue, setSearchValue] = useState("");
  const CLIENTE_BATCH_SIZE = 10;
  const CLIENTE_SIZE_LIST = 1024;
  const [clienteFullList, setClienteFullList] = useState([]);
  const [clienteCadastroList] = useState([]);
  const [clienteFilteredList, setClienteFilteredList] = useState([]);
  const [typedCliente] = useState("");
  const [clienteCadastroModalOpen, setClienteCadastroModalOpen] = useState(false);
  const [clienteSelecionado, setClienteSelecionado] = useState(null);
  const [dialogConfirmation, setDialogConfirmation] = useState({open: false, keyword: "", title: "", description: "", inscricao: "", buttons: [], result: ""});
  
  const handleClose = () => {
    setTimeout(() => {
      onClose(clienteSelecionado);     
    },200)    
  }

  const handleConfirmaSelecaoCliente = (cliente) => {
    setClienteSelecionado(cliente);
		setDialogConfirmation({...dialogConfirmation, keyword: "confirmar", 
                                                open: true, 
                                                title: "Confirmar cliente", 
                                                description: `${cliente.nome}`,
                                                inscricao: `${cliente.inscricaoFormatada}`,
                                                buttons: ["Confirmar", "Cancelar"]});
	}

  const handleCloseDialogConfirmation = (result) => {
		setDialogConfirmation({...dialogConfirmation, keyword: "", open: false, result: result});

		if (result === "Confirmar") {  
      handleSelectCliente()
    } else {
      setClienteSelecionado(null)
    }
	};

  const handleChangeSearchValue = (event) => {
    const typedSearch = event.target.value.toLowerCase();

    setSearchValue(typedSearch);

    setClienteFilteredList(
      clienteFullList
        .filter(cliente => cliente.nome.toLowerCase().includes(typedSearch) || 
                           cliente.inscricao.toLowerCase().includes(typedSearch) || 
                           cliente.inscricaoFormatada.toLowerCase().includes(typedSearch))
        .slice(0, CLIENTE_BATCH_SIZE)
    );
  };

  const handleSelectCliente = () => {
    handleClose();
  };

  const handleScroll = (event) => {
    if (clienteFilteredList.length === clienteFullList.length) return;

    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

    if (scrollHeight - (scrollTop + 10) < clientHeight) {
      setClienteFilteredList(previousValue => {
        return [
          ...previousValue,
          ...clienteFullList
            .filter(cliente => cliente.nome.toLowerCase().includes(searchValue) || 
                               cliente.inscricao.toLowerCase().includes(searchValue) || 
                               cliente.inscricaoFormatada.toLowerCase().includes(searchValue))
            .slice(previousValue.length, previousValue.length + CLIENTE_BATCH_SIZE)
        ];
      });
    }
  };

  const fetchClienteList = async () => {
    const { data : clienteData } = await api.get("v1/clientes/", {
      params: { size: CLIENTE_SIZE_LIST },
    });
    let clienteList = [];

    clienteData.clientes.forEach(cliente => clienteList.push({ ...cliente, 
                                                               inscricaoFormatada: formatarCnpjCpf(cliente.inscricao) }));

    setClienteFullList(clienteList);
    setClienteFilteredList(
      clienteList
        .filter(cliente => cliente.nome.toLowerCase().includes(typedCliente) || 
                           cliente.inscricao.toLowerCase().includes(typedCliente) ||
                           cliente.inscricaoFormatada.toLowerCase().includes(typedCliente) )
        .slice(0, CLIENTE_BATCH_SIZE)
    );
  };

  useEffect(() => {
    if (open) {
      fetchClienteList();
    }
  }, [open, typedCliente]);

  const handleOpenCadastroModal = () => {
    setClienteCadastroModalOpen(true);
  };

  const handleCloseCadastroModal = () => {
    setClienteCadastroModalOpen(false);
    fetchClienteList();
  };

  return (
    <>    

      <DialogModal
				title={i18n.t(dialogConfirmation.title)}
				open={dialogConfirmation.open}
				onClose={handleCloseDialogConfirmation}  
				description={<div>{i18n.t("clienteSearchModal.clienteConfirma")}<strong> <br/> {dialogConfirmation.description}</strong> <br/> {dialogConfirmation.inscricao}? </div>}   
				buttons={dialogConfirmation.buttons}      
				>				
			</DialogModal> 

      <ClienteModal
        open={clienteCadastroModalOpen}
        onClose={handleCloseCadastroModal}
        clientes={clienteCadastroList}
      />

      <Dialog
          open={open}      
          fullWidth={true}
          maxHeight={true}          
          PaperProps={ {
            style: {
              borderRadius: 10,
              height: isSmallScreen ? "70%" : "35em",
              width: isSmallScreen ? "100%" : "30%",
              maxWidth: "50vh",
              minWidth: isSmallScreen ? "350px" : "500px"
            },
          }}       
      >       
        {
        isSmallScreen
          ? (
            <MainHeaderFX
              title={i18n.t("clienteSearchModal.title")}
              leftContainerType="modal"
              rightContainerType="hidden"
              handleCloseModal={handleClose}
            />
          )
          : 
            <div className="clienteTitle">
              <DialogTitle>{i18n.t("clienteSearchModal.title")}</DialogTitle>
              <Button className="clienteSearchModal.CloseButton" onClick={handleClose}>X</Button>
            </div>
        }

        <DialogContent className="clienteSearchModalDialogContent" dividers>
          <div style={{width: "100%"}}> 
            <TextField  
              autoFocus
              style={{width: "85%", paddingRight: "5px"}}
              value={searchValue}
              onChange={handleChangeSearchValue}
              label={i18n.t("clienteSearchModal.cliente")}
              type="text"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
            <Button            
              tabIndex="-1"
              variant="contained"         
              style={{width: "10%", height: 50}}
              onClick={handleOpenCadastroModal}
            >
              <GroupAddIcon/>
            </Button>
          </div>

          <Divider />
          <Divider />
          <Divider />
          <Divider />

          <TableContainer onScroll={handleScroll}>
            <Table size="small" stickyHeader style={{ maxHeight: 150 }}>

              {clienteFilteredList.length <= 0 && 
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{i18n.t("clienteSearchModal.notFound")}</TableCell>
                  </TableRow>
                </TableHead>}

              <TableBody>
                
                {clienteFilteredList.length > 0 && clienteFilteredList.map(cliente => (
                  <TableRow key={cliente.id} className="clienteSearchModalTableRow" onClick={() => handleConfirmaSelecaoCliente(cliente)}>
                    <TableCell className="clienteSearchModalTableCell"><b>{cliente.nome}</b> - {cliente.inscricaoFormatada}</TableCell>
                  </TableRow>
                ))}

              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      
      </Dialog>
    </>
  );
};

export default InformarCliente;