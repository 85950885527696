import React, { useState } from "react";
import { Button, Dialog, Container, useMediaQuery } from "@mui/material";
import "./style.css"
import MainHeaderFX from "../MainHeaderFx";
import { validarCpfCnpj } from "../../utils/funcoes/validacoes/validarCpfCnpj";
import FlexMessage from "../message/FlexMessage";
import BackspaceIcon from '@mui/icons-material/Backspace';
import { formatarCnpjCpf } from "../../utils/formatacao/cnpjCpf.js";

const InformarCpf = ({ open, onClose, onCpfCnpjSelecionado, cnpjEmpresa }) => {  
  const isSmallScreen = useMediaQuery('(max-width:825px)');   
  const [cpfCnpj, setCpfCnpj] = useState("")
  const [messagemErro, setMensagemErro] = useState(false)  
  const [count, setCount] = useState("");  
  const [descricaoErro, setDescricaoErro] = useState(false)    

  const handleClose = () => {
    onCpfCnpjSelecionado("")
    setCpfCnpj("")

    setTimeout(() => {
      onClose();     
    },200)    
  }

  const handleConfirmaCpf = () => {
    if (cpfCnpj !== "") {
      const valido = validarCpfCnpj(cpfCnpj)

      if (!valido) {
        setMensagemErro(true)
        setDescricaoErro(cpfCnpj.length < 14 ? "CPF inválido" : "CNPJ  inválido")      
      } else {
        if (cnpjEmpresa === cpfCnpj) {
          setMensagemErro(true)
          setDescricaoErro("Não é possível fazer uma NFC-e para o CNPJ do emitente!")
        }
        else {
          onCpfCnpjSelecionado(cpfCnpj)
          setCpfCnpj("")
          
          setTimeout(() => {
            onClose();     
          },200)
        }
      } 
    } else {
      setTimeout(() => {
        onClose();     
      },200)
    }
       
  }

  const adicionarNumero = (numero) => { 
    if (cpfCnpj.length + 1 > 14) {
      return ""
    }
    const cpfCnpjFomatado = (prevState => prevState +""+ numero)   
    setCpfCnpj(cpfCnpjFomatado);
  };

  const apagarUltimoDigito = () => {
    setCpfCnpj(cpfCnpj.slice(0, -1));
    setCount(count - 1);
  };

  return (
    <>    
      <FlexMessage
        open={messagemErro}
        onClose={() => setMensagemErro(false)}
        title={cpfCnpj.length < 14 ? "CPF" : "CNPJ"}
        tipo={"error"}
        // message={cpfCnpj.length < 14 ? "CPF inválido" : "CNPJ  inválido"}
        message={descricaoErro}
      />

      <Dialog
          open={open}      
          fullWidth={true}
          maxHeight={true}          
          PaperProps={ {
            style: {
              borderRadius: 10,
              height: isSmallScreen ? "70%" : "35em",
              width: isSmallScreen ? "100%" : "30%",
              maxWidth: "50vh",
              minWidth: isSmallScreen ? "350px" : "500px"
            },
          }} 
          onKeyDown={(event) => {
            if (open) {
              const allowedKeys = [
                '0', '1', '2', '3', '4', '5', '6',
                '7', '8', '9', 'Backspace', isSmallScreen && ',', 'Enter', 'Escape'
              ];
  
              if (allowedKeys.includes(event.key)) {
                if (event.key === '0') document.getElementById('zeroKey').click();
                if (event.key === '1') document.getElementById('oneKey').click();
                if (event.key === '2') document.getElementById('twoKey').click();
                if (event.key === '3') document.getElementById('threeKey').click();
                if (event.key === '4') document.getElementById('fourKey').click();
                if (event.key === '5') document.getElementById('fiveKey').click();
                if (event.key === '6') document.getElementById('sixKey').click();
                if (event.key === '7') document.getElementById('sevenKey').click();
                if (event.key === '8') document.getElementById('eightKey').click();
                if (event.key === '9') document.getElementById('nineKey').click();
                if (event.key === 'Backspace') document.getElementById('backspaceKey').click();
                if (event.key === ',') document.getElementById('virgulaKey').click();
                if (event.key === 'Enter') {handleConfirmaCpf();}
                if (event.key === 'Escape') {
                  setCpfCnpj("");
                  handleClose();
                }
              }
            }
          }}       
      >        
        <MainHeaderFX 
          title={"CPF / CNPJ"}
          leftContainerType={"hidden"}
          rightContainerType={"hidden"}   
          backModal={true}          
        />

        <Container class="containerCpfCnpj" style={{height: "20%"}}>
          {formatarCnpjCpf(cpfCnpj)}
        </Container>

        <Container class="containerCalculadora" style={{height: "70%"}}>
          <Container class="calculadora">
            <Container class="numeros">               
              <Button tabIndex={"-1"} id="oneKey" disableRipple class="buttonCalculadora" onClick={() => adicionarNumero(1)}>1</Button>
              <Button tabIndex={"-1"} id="twoKey" disableRipple class="buttonCalculadora" onClick={() => adicionarNumero(2)}>2</Button>
              <Button tabIndex={"-1"} id="threeKey" disableRipple class="buttonCalculadora" onClick={() => adicionarNumero(3)}>3</Button>
              <Button tabIndex={"-1"} id="fourKey" disableRipple class="buttonCalculadora" onClick={() => adicionarNumero(4)}>4</Button>
              <Button tabIndex={"-1"} id="fiveKey" disableRipple class="buttonCalculadora" onClick={() => adicionarNumero(5)}>5</Button>
              <Button tabIndex={"-1"} id="sixKey" disableRipple class="buttonCalculadora" onClick={() => adicionarNumero(6)}>6</Button>
              <Button tabIndex={"-1"} id="sevenKey" disableRipple class="buttonCalculadora" onClick={() => adicionarNumero(7)}>7</Button>
              <Button tabIndex={"-1"} id="eightKey" disableRipple class="buttonCalculadora" onClick={() => adicionarNumero(8)}>8</Button>
              <Button tabIndex={"-1"} id="nineKey" disableRipple class="buttonCalculadora" onClick={() => adicionarNumero(9)}>9</Button>
              <Button tabIndex={"-1"} id="virgulaKey" disableRipple class="buttonCalculadora" onClick={() => setCpfCnpj("")}>C</Button>

              <Button tabIndex={"-1"} id="zeroKey" disableRipple class="buttonCalculadora" onClick={() => adicionarNumero(0)}>0</Button>            
              <Button tabIndex={"-1"} id="backspaceKey" disableRipple class="buttonCalculadora" onClick={() => apagarUltimoDigito()}>{<BackspaceIcon color="action" disableRipple/>}</Button>         
          </Container>
          </Container>
          
        </Container>
        
        <div class="opcaoButton" style={{height: "20%"}}
        >
          <Button            
            tabIndex="-1"
            color="primary"
            variant="contained"
            onClick={() => {handleConfirmaCpf()}}          
            style={{width: "95%", height: 50, borderRadius: 10}}
          >
            Continuar
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default InformarCpf;