export const gerarIcmsTot = (operacaoOpcao, totalNota, vTotTrib) => {
  const icmsTot = {  
		  id: operacaoOpcao.alterar ? totalNota.id : undefined,  		
			vBC: 0,
			vICMS: 0,
			vICMSDeson: 0,
			vFCP: 0,
			vBCST: 0,
			vST: 0,
			vFCPST: 0,
			vFCPSTRet: 0,
			vProd: totalNota.notaId ? totalNota.vProd : totalNota.valorProduto,
			vFrete: 0,
			vSeg: 0,
			vDesc: totalNota.notaId ? totalNota.vDesc : totalNota.valorDesconto,
			vII: 0,
			vIPI: 0,
			vIPIDevol: 0,
			vPIS: 0,
			vCOFINS: 0,
			vOutro: 0,
			vNF: totalNota.notaId ? totalNota.vNF : totalNota.totalNf, 
			vTotTrib: totalNota.notaId ?  totalNota.vTotTrib : vTotTrib
  }

  return icmsTot
}