import React from "react";

import { Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle
} from "@mui/material";  

import "./style.css";

const DialogModal = ({ title, description, open, onClose, buttons }) => {
	return (
		<Dialog
			open={open}
			onClose={() => onClose("")}
		>
			<DialogTitle >{title}</DialogTitle>
			<DialogContent dividers>
				{description}
			</DialogContent>
			<DialogActions>
				{buttons?.map(array => (<Button onClick={() => onClose(array)}>{array}</Button>))}
				
			</DialogActions>
		</Dialog>
		
	);
};

export default DialogModal;
