import React, { useState, useEffect } from "react";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";

import { createTheme, 
  ThemeProvider, 
  CssBaseline, 
  FormGroup, 
  
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import { ptBR } from '@mui/material/locale';

const useStyles = makeStyles(() => ({
  switch: {
    margin: "2px",
    position: 'absolute', 
    right: 0,
    display: "none",
  },
}));

const App = () => {
  const [locale, setLocale] = useState();
  const classes = useStyles();

  const lightTheme = createTheme(
    {
      scrollbarStyles: {
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
          backgroundColor: "#e8e8e8",
        },
      },
      palette: {
        primary: { main: "#4f9ebd", pastel: "#ff6961", messageBox: "#dcf8c6"},
        background: {
          default:  "#F6F6F6",
          paper: "#F6F6F6", 
        },
        text: {
          //Cor dos texto 
          primary: "#545c67", 
          corDetalhes: "#f0f", 
          reaction: '#f0f0f0',
        },
      },
      backgroundImage: "",
    },
    locale
  );

  const darkTheme = createTheme(
    {
      overrides: {
        MuiCssBaseline: {
          "@global": {
            body: {
              backgroundColor: "#282b30",
            }
          }
        }
      },
      scrollbarStyles: {
         "&::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
          backgroundColor: "#ffffff",
        },
      },
      palette: {
        primary: { main: "#3C79F5", pastel: "#ff6961", messageBox: "#005d4b" },
        secondary: { main: "#3C79F5" },
        background: {
          default:  "#282b30",
          paper: "#282a36",
        },
        text: {
          primary: '#E9E8E8',
          secondary: '#777777',         
          reaction: '#36393e',
        },
      },
      backgroundImage: "",
    },
    locale
  );

  useEffect(() => {
    // sempre que for utilizar uma localStorage, adicionar uma condição aqui
    // para inializá-la pela primeira vez que o usuário for usar a aplicação
    if (localStorage.getItem("theme") === null) {
      localStorage.setItem("theme", "light");
    }

    const i18nlocale = localStorage.getItem("i18nextLng");
    const browserLocale = i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);
    if (browserLocale === "ptBR") { setLocale(ptBR); }
  }, []);

  const [checked, setChecked] = React.useState(false);
  const [theme, setTheme] = useState("light");

  const themeToggler = () => {
    theme === "light" ? setTheme("dark") : setTheme("light");
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if(checked === false) {
      themeToggler();
    }
    else if (checked === true ){
      themeToggler();
    }
  };

  return (
    <ThemeProvider theme={localStorage.getItem("theme") === "light" ? lightTheme : darkTheme}>
      
      <Routes />      
      <CssBaseline />      
      <FormGroup row className={classes.switch}>
          {/* <FormControlLabel control={
            <Switch id="containerToggle"
              checked={checked}
              onChange={handleChange}
            />} 
          /> */}
      </FormGroup>      
    </ThemeProvider>
  );
};

export default App;