import React, { useEffect, useReducer, useState } from "react";
import {
  Container,
  Paper,
  SpeedDial,
  SpeedDialIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useMediaQuery,
} from "@mui/material";

import { Configuracao } from "../../configuracao/index.js";
import { formatarCnpjCpf } from "../../utils/formatacao/cnpjCpf.js";
import { i18n } from "../../translate/i18n.js";
import api from "../../services/api.js";
import EmpresaModal from "../../components/EmpresaModal";
import MainContainer from "../../components/MainContainer/index.js";
import MainHeaderFx from "../../components/MainHeaderFx";
import TableRowSkeleton from "../../components/TableRowSkeleton/index.js";
import toastError from "../../errors/toastError.js";
import naoEncontrado from "../../assets/naoEncontrado.svg"

import "./style.css";

const reducer = (state, action) => {      
  if (action.type === "LOAD_EMPRESA") {
    const empresas = action.payload;
    const newEmpresas = [];

    empresas.forEach((empresa) => {
      const empresaIndex = state.findIndex((u) => u.id === empresa.id);
      if (empresaIndex !== -1) { state[empresaIndex] = empresa; }
      else { newEmpresas.push(empresa); }
    });   

    return [...state, ...newEmpresas];
  }

  if (action.type === "RESET") { return []; }
};

const Empresa = () => {
  //  ***************
  //  ** Variáveis **
  //  ***************
  const [empresas, dispatch] = useReducer(reducer, []);
  const isSmallScreen = useMediaQuery('(max-width:825px)');
  
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [isScroll, setIsScroll] = useState(false);
  const [search, setSearch] = useState("");
  
  const [empresaModalOpen, setEmpresaModalOpen] = useState(false);
  const [selectedEmpresa, setSelectedEmpresa] = useState(null); 
  
  useEffect(() => {
    dispatch({ type: "RESET" });
    setPage(1);
  }, [search]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchEmpresas = async () => {
        try {             
          const { data } = await api.get("/v1/empresas/", {
            params: { search, page },
          });     
          
          setIsScroll(true);
          dispatch({ type: "LOAD_EMPRESA", payload: data.empresas});          
          setHasMore(data.hasMore);
          setTotalCount(data.count);
          setLoading(false);
        } catch (err) { toastError(err); }
      };
      fetchEmpresas();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search, page]);  
  
  const handleOpenModal = () => { 
      setEmpresaModalOpen(true); 
  }

  const handleEditModal = (empresa) => {    
    setSelectedEmpresa(empresa);
    setEmpresaModalOpen(true);
  }

  const handleCloseEmpresaModal = () => {
    setEmpresaModalOpen(false)
    setSelectedEmpresa(null);
  }    

  const loadMore = () => {   
    if (isScroll) {
      setPage((prevState) => prevState + 1);
      setIsScroll(false);
    }    
  };

  const handleScroll = (e) => {  
    if (!hasMore || loading) return;    
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 1) < clientHeight) { loadMore(); }
  }; 
  
  return (
    <div className="pageRoot">
      <EmpresaModal        
        open={empresaModalOpen}
        onClose={handleCloseEmpresaModal}        
        empresaId={selectedEmpresa && selectedEmpresa.id}
        empresas={empresas}
      />
      
      <MainHeaderFx
        title={i18n.t("empresa.pluralTitle")}
        leftContainerType={"page"} 
        rightContainerType={"search"}
        search={search}
        setSearch={setSearch}
        titleButton={i18n.t("button.add")}
        backParam={"/"}
        handleModal={handleOpenModal}
        useHandleModal={!loading && empresas.length <= 0}
      />

      <MainContainer>
        <Paper className="scroll" onScroll={handleScroll}>
          <TableContainer>        
            <Table>
              <TableBody>
                {empresas.map((empresa, index) => (   
                   
                  <TableRow key={index} onClick={() => handleEditModal(empresa)}>
                    <TableCell> {empresa.razaoSocial} </TableCell>                    
                    <TableCell><b>{formatarCnpjCpf(empresa.cnpj)}</b></TableCell>
                  </TableRow>
                ))}
                {loading && <TableRowSkeleton columns={4} />}
              </TableBody>

            </Table>
          </TableContainer>

          {empresas.length <= 0 && totalCount === 0 && !loading && (
            <Container className="record">              
              <img alt="consulta" 
                id="logoTipo"                         
                style={{ width: "70%", height: "30%" }} 
                src={naoEncontrado}
              /> 
              <br/>    
              <span style={{fontSize: "1em", fontWeight:"bold", opacity: 0.85}} >{Configuracao.LISTAGEM_VAZIA} </span>
            </Container>
          )}
          
          {empresas.length <= 0 && (
            isSmallScreen && (
              <SpeedDial className= "iconAdd" 
                ariaLabel="Add Company" 
                onClick={handleOpenModal} 
                open={false} 
                icon={<SpeedDialIcon/>} 
              />
            )
          )}
        </Paper>      
      </MainContainer>      
    </div>
  );
};

export default Empresa;