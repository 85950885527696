export const buscarNaturezaOperacao = (operacao) => {
  /* Operacao
  01-Revenda de mercadoria. (Comércio)"
  02-Revenda de mercadoria efetuada fora do estabelecimento. (Comércio)"
  03-Venda de produção própria (Indústria ou equiparada)"
  04 Venda de produção própria efetuada fora do estabelecimento. (Indústria ou equiparada)*/

  let naturezaOperacao = "Revenda de mercadoria"

  if (operacao === "03") {    
    naturezaOperacao = "Venda de produção própria"
  }
  else if (operacao === "04") {    
    naturezaOperacao = "Venda de produção própria"
  }

  return naturezaOperacao
}