import React from "react";
import { TextField, useMediaQuery } from "@mui/material";
import { Field } from "formik";

const FlexTextField = ({
  label,
  name,
  width,
  inputMode = "text",
  autoFocus = false,
  error,
  helperText,
  disabled = false,
}) => {
  const isSmallScreen = useMediaQuery("(max-width:825px)");

  return (
    <Field
      as={TextField}
      label={label}
      name={name}
      variant="outlined"
      margin="dense"
      fullWidth={isSmallScreen}


      autoFocus={autoFocus}
      style={{ width: isSmallScreen? "100%" : width }}
      inputProps={{ inputMode: inputMode }}
      error={error}
      helperText={helperText}
      disabled={disabled}
    />
  );
};

export default FlexTextField;
