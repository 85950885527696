export const gerarCfo = (csosn, ide, dest) => {
  /* Operacao
  01-Revenda de mercadoria. (Comércio)"
  02-Revenda de mercadoria efetuada fora do estabelecimento. (Comércio)"
  03-Venda de produção própria (Indústria ou equiparada)"
  04 Venda de produção própria efetuada fora do estabelecimento. (Indústria ou equiparada)*/

  const primeiroDigito = ide.idDest === "2" ? "6" : "5"; /* 6000/5000 */

  let cfo = "102" /* 5102/6102 */

  if (ide.operacao === "01") {    
    cfo = csosn === "500" ? (primeiroDigito === "5" ? "405" : "404") : (dest && dest?.indIEDest === "9" && primeiroDigito === "6" ? "108" : "102") /* 5405/6404 ou 5102/6102/6108 */
  }
  else if (ide.operacao === "02") {    
    cfo = csosn === "500" ? (primeiroDigito === "5" ? "405" : "404") : "104" /* 5405/6404 ou 5104/6104 */
  }
  else if (ide.operacao === "03") {    
    cfo = "101" /* 5101/6101 */
  }
  else if (ide.operacao === "04") {    
    cfo = "103" /* 5103/6103 */
  }

  return primeiroDigito + cfo
}