export const gerarDetPag =  (operacaoOpcao, arrayPagamento) => {
	let detPag = []

	if (operacaoOpcao.devolver) {
		detPag.push({
			id: undefined,				
			tPag: "90",
			vPag: "0"
		});	
	} else {
		Promise.all (arrayPagamento.map(async (pagamento) => { 
			let indicador = "0";
	
			if (pagamento.tPag === "05" || pagamento.tPag === "15" || pagamento.tPag === "99") {
				indicador = "1";
			}
	
			detPag.push({
				id: operacaoOpcao.alterar ? pagamento.id : undefined,
				indPag: indicador,				
				tPag: pagamento.tPag,
				vPag: pagamento.vPag
			});
		}));
	}	

  return detPag
}