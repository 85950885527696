import React, { useEffect, useState } from "react";
import {
  Button,
	Dialog,
	DialogContent,
  DialogTitle,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
	useMediaQuery	 
} from '@mui/material';
import { useFormikContext } from "formik";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import MainHeaderFX from "../MainHeaderFx";

import "./styles.css";

const NcmSearchModal = ({ open, onClose, typedNcm = "" }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const isSmallScreen = useMediaQuery("(max-width: 825px)");
  const { setFieldValue } = useFormikContext();

  const [searchValue, setSearchValue] = useState(typedNcm);
  
  const NCM_BATCH_SIZE = 50;
  const [ncmFullList, setNcmFullList] = useState([]);
  const [ncmFilteredList, setNcmFilteredList] = useState([]);



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    if (open) setSearchValue(typedNcm);
  }, [open, typedNcm]);

  useEffect(() => {
    if (open) {
      const fetchNcmList = async () => {
        // ***---- Fetching NCM Version ----***
        const { data: ncmVersionData } = await api.get('/v1/ncm-imposto-aproximado-versao');
        const ncmVersionLocalStorage = localStorage.getItem('ncmListVersion');
        const ncmListLocalStorage = localStorage.getItem('ncmList');

        // ***---- Fetching NCMs ----***
        let ncmList = [];

        if (ncmVersionLocalStorage === ncmVersionData && ncmListLocalStorage !== null) {
          ncmList = JSON.parse(ncmListLocalStorage);
        }
        else {
          const { data: ncmListData } = await api.get('/v1/ncm-imposto-aproximado');
          ncmListData.forEach(ncm => ncmList.push({ id: ncm.id, codigo: ncm.codigo, descricao: ncm.descricao }));
          localStorage.setItem('ncmList', JSON.stringify(ncmList));
          localStorage.setItem('ncmListVersion', ncmVersionData);
        }

        // ***---- Filtering NCMs ----***
        setNcmFullList(ncmList);
        setNcmFilteredList(
          ncmList
            .filter(ncm => ncm.codigo.toLowerCase().startsWith(typedNcm) || ncm.descricao.toLowerCase().includes(typedNcm))
            .slice(0, NCM_BATCH_SIZE)
        );
      };

      fetchNcmList();
    }
  }, [open, typedNcm]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => {
    onClose();
    setSearchValue(typedNcm);
  };

  const handleChangeSearchValue = (event) => {
    const typedSearch = event.target.value.toLowerCase();

    setSearchValue(typedSearch);

    setNcmFilteredList(
      ncmFullList
        .filter(ncm => ncm.codigo.toLowerCase().startsWith(typedSearch) || ncm.descricao.toLowerCase().includes(typedSearch))
        .slice(0, NCM_BATCH_SIZE)
    );
  };

  const handleSelectNcm = (ncmCodigo) => {
    setFieldValue('tributacao.ncm', ncmCodigo);
    handleClose();
  };

  const handleScroll = (event) => {
    if (ncmFilteredList.length === ncmFullList.length) return;

    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      setNcmFilteredList(previousValue => {
        return [
          ...previousValue,
          ...ncmFullList
            .filter(ncm => ncm.codigo.toLowerCase().startsWith(searchValue) || ncm.descricao.toLowerCase().includes(searchValue))
            .slice(previousValue.length, previousValue.length + NCM_BATCH_SIZE)
        ];
      });
    }
  };

  

  //  ************
  //  ** Return **
  //  ************
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={isSmallScreen}
      fullWidth
      maxWidth="lg"
      scroll="paper"
      classes={{ paper: "ncmSearchModalDialogPaper" }}
    >
      {
        isSmallScreen
          ? (
            <MainHeaderFX
              title={i18n.t("ncmSearchModal.title")}
              leftContainerType="modal"
              rightContainerType="hidden"
              handleCloseModal={handleClose}
            />
          )
          : 
            <div className="ncmTitle">
              <DialogTitle>{i18n.t("ncmSearchModal.title")}</DialogTitle>
              <Button className="ncmSearchModal.CloseButton" onClick={handleClose}>X</Button>
            </div>
        }
      
      <DialogContent className="ncmSearchModalDialogContent" dividers>
        <TextField  
          autoFocus
          fullWidth
          value={searchValue}
          onChange={handleChangeSearchValue}
          label={i18n.t("ncmSearchModal.ncm")}
          type="text"
          variant="outlined"
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />

        <Divider />
        <Divider />
        <Divider />
        <Divider />

        <TableContainer onScroll={handleScroll}>
          <Table size="small" stickyHeader style={{ maxHeight: 150 }}>
            <TableHead>
              <TableRow>
                <TableCell align="center">{i18n.t("ncmSearchModal.ncm")}</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              
              {ncmFilteredList.length > 0 && ncmFilteredList.map(ncm => (
                <TableRow key={ncm.id} className="ncmSearchModalTableRow" onClick={() => handleSelectNcm(ncm.codigo)}>
                  <TableCell className="ncmSearchModalTableCell"><b>{ncm.codigo}</b> - {ncm.descricao}</TableCell>
                </TableRow>
              ))}

              {ncmFilteredList.length === 0 && (
                <TableRow className="ncmSearchModalTableRow">
                  <TableCell className="ncmSearchModalTableCell"></TableCell>
                </TableRow>
              )}

            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

export default NcmSearchModal;
