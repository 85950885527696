export const Configuracao = {    
    
    LIMITE_DE_LINHAS: 15,   
    
    LISTAGEM_VAZIA: 'Nenhum registro encontrado.',    

    VERSAO_SISTEMA: '2024.04.17.16.20',
    VERSAO_NFCE: '4.00',

    COR_PRIMARY: '#4f9ebd',  
    COR_SECUNDARY: '#ebebeb'  ,
    COR_TERCIARY: '#07689f',
    COR_CANCEL: '#CE6262',    
    COR_4: '#ffc93c',
    COR_5: '#b1bbc9',
    COR_6: '#f6f6ff',
    COR_7: '#e6e9eb'
    
  };
  