import React from "react";
import { makeStyles } from "@mui/styles";

import { Configuracao } from "../../configuracao";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    flex: 1,
    padding: 0,
    background: Configuracao.COR_6,
    width: "100%",
    height: "calc(100% - 67px)",
  },

  contentWrapper: {
    height: "100%",
    overflowY: "hidden",
    display: "flex",
    flexDirection: "column",
    width: "calc(100%)",
  },
}));

const MainContainer = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.mainContainer}>
      <div className={classes.contentWrapper}>{children}</div>
    </div>
  );
};

export default MainContainer;
