import React, { useEffect, useReducer, useState } from "react";

import { Button,   
  TableHead, 
  Table, 
  Paper, 
  TableRow, 
  TableCell, 
  TableBody,  
  IconButton, 
  TextField, 
  InputAdornment, 
  TableContainer, 
  TableFooter, 
  LinearProgress,
  Pagination,
  
} from "@mui/material";  

import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from "@mui/styles";

import { DeleteOutline, Edit } from "@mui/icons-material"

import Title from "../../components/Title";
import MainHeader from "../../components/MainHeader";
import EmbalagemModal from "../../components/EmbalagemModal";
import MainContainer from "../../components/MainContainer";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ConfirmationModal from "../../components/ConfirmationModal";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";

import api from "../../services/api";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import { Configuracao } from "../../configuracao/index"

const reducer = (state, action) => {
  if (action.type === "LOAD_EMBALAGEM") { 
    
    const embalagem = action.payload.embalagens;    
    const novaEmbalagem = [];   

    embalagem.forEach((embalagens) =>{
      const embalagensIndex = state.findIndex((s) => s.id === embalagens.id);

      if (embalagensIndex !== -1) {
        state[embalagensIndex] = embalagens;
      } else {
        novaEmbalagem.push(embalagens);
      }
    });

    return[...state, ...novaEmbalagem];    
  }

  if (action.type === "UPDATE_EMBALAGEM") {
    const embalagem = action.payload;
    const embalagemIndex = state.findIndex((u) => u.id === embalagem.id);

    if (embalagemIndex !== -1) {
      state[embalagemIndex] = embalagem;
      return [...state];
    } else {
      return [embalagem, ...state];
    }
  }

  if (action.type === "DELETE_EMBALAGEM") {          
    const embalagemId = action.payload;
    const embalagemIndex = state.findIndex((u) => u.id === embalagemId);

    if (embalagemIndex !== -1) {
      state.splice(embalagemIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  paginaPrincipal: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },  

  icone: {
		color: theme.palette.text.primary,
	},
  
  adicionarBotao: {
    transition: 'transform 0.3s',
  
    '&:hover': {
       transform: 'translateY(-5px)',
    },
  },
  
  acaoBotao: {
    "&:hover": {
       color: theme.palette.primary.main,
    },
  },
  
  statusProduto:{     
    color: "#F0F0F0F0",
    padding: "3px",   
    borderRadius: "3px",    
  },
  ticketsTable: {

		// scroll
		"& .MuiDataGrid-virtualScroller": {
			...theme.scrollbarStyles,
		},

		// header
		"& .MuiDataGrid-columnHeaders": {
			backgroundColor: theme.palette.background.paper,
			color: theme.palette.text.primary,      
			fontSize: 12,
		},

		// rows
		"& .MuiDataGrid-cell": {
			color: theme.palette.text.primary,
		},

		// pair rows
		"& .MuiDataGrid-virtualScrollerRenderZone": {
			"& .MuiDataGrid-row": {
				"&:nth-child(2n)": {
					backgroundColor: theme.palette.background.paper,
				}
			}
		},

		// buttons
		"& .MuiButtonBase-root": {
			color: theme.palette.text.primary,
		},

		// checkboxes - not checked
		"& [data-testId='CheckBoxOutlineBlankIcon']": {
			color: theme.palette.text.primary,
		},

		// checkboxes - checked
		"& [data-testId='CheckBoxIcon']": {
			color: theme.palette.primary.main,
		},

		// checkbox - header (unselect all)
		"& [data-testId='IndeterminateCheckBoxIcon']": {
			color: theme.palette.primary.main,
		},

		// footer - selected rows
		"& .MuiDataGrid-footerContainer": {
			color: theme.palette.text.primary,
		},

		// footer - pagination
		"& .MuiTablePagination-root": {
			color: theme.palette.text.primary,
		},
	},
  OrdenacaoHeader: {
    visibility: "hidden"
  },
  OrdenacaoTrueHeader: {
    visibility: "visible"
  },
  houveGrid:{
    "&:hover" : {
      backgroundColor: theme.palette.text.reaction 
  }
  }
}));

const Embalagem = () => {   
  const [embalagem, dispatch] = useReducer(reducer,[]);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [abrirEmbalagemModal, setAbrirEmbalagemModal] = useState(false);
  const [selecionaEmbalagem, setSelecionaEmbalagem] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false); 
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [hasMore, setHasMore] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
   
  useEffect(() => {
   dispatch({ type: "RESET" });
    setPage(1);
  }, [search]); 

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchEmbalagem= async () => {
        try {
          
          const { data } = await api.get("/embalagem/", {
            params: { search, page },
          });               
          
          dispatch({ type: "LOAD_EMBALAGEM", payload: data });
          setHasMore(data.hasMore);
          setTotalCount(data.count);          
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchEmbalagem();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search, page]);

  const abrirCadastroEmbalagem = (embalagem) => {    
    setSelecionaEmbalagem(embalagem);
    setAbrirEmbalagemModal(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelecionaEmbalagem(null);
  };

  const handleCloseEmbalagemModal = () => {
    setSelecionaEmbalagem(null);
    setAbrirEmbalagemModal(false);
  };

  const excluirEmbalagem = async (embalagemId) => {    
    try {
      await api.delete(`/embalagem/${embalagemId}`);
      toast.success("Embalagem excluído com sucesso!");
    } catch (err) {
      toastError(err);
    }
    setSelecionaEmbalagem(null);
  };

  const pesquisar = (event) => {
    setSearch(event.target.value.toLowerCase());
  };

  const loadMore = () => {   
    setPage((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {        
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 200) < clientHeight) {      
      loadMore();      
    }
  };   

  const header = [
    {name: "Código", prop: "codigo"},
    {name: "Descrição", prop: "descricao"},
    {name: "Unidade", prop: "unidade"},
    {name: "Fator", prop: "fator"},
    {name: "Ações", prop: "acoes"},    
  ];
     
  return (        
    <MainContainer >            
      <ConfirmationModal
          title={"Excluir?"}
          open={confirmModalOpen}
          onClose={handleCloseConfirmationModal}
          onConfirm={() => excluirEmbalagem(selecionaEmbalagem.id)}          
        >
          {"Você tem certeza? Essa ação não pode ser revertida!"}
          
      </ConfirmationModal>
      <EmbalagemModal
        open={abrirEmbalagemModal}
        onClose={handleCloseEmbalagemModal}
        embalagemId={selecionaEmbalagem?.id}          
      />
      <MainHeader>
        <Title>{"Embalagem de produtos"}</Title>
      </MainHeader>

      <MainHeader>
        <Title>{"Embalagem de produtos"}</Title>
        <MainHeaderButtonsWrapper>
            <TextField
              placeholder={"Pesquisar"}
              type="search"
              value={search}
              onChange={pesquisar}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "gray" }} />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={abrirCadastroEmbalagem}
              className={classes.adicionarBotao}
            >
              {"Adicionar Embalagem"}
              
            </Button>           
        </MainHeaderButtonsWrapper>        
      </MainHeader>        
          
      <Paper 
        className={classes.paginaPrincipal} 
        variant="outlined"
        onScroll={handleScroll}
      >
        <TableContainer variant="outlined" sx={{ width: "auto"}}>
          <Table
          >            
            <TableHead> 
              <TableRow>
                {header.map((x, i) => (
                  <TableCell key={`thc-${i}`}>{x.name}  </TableCell>
                ))}      
                
              </TableRow>
            </TableHead> 

            <TableBody>
              <>
              {embalagem.map((embalagem) => (             
                      
                      <TableRow className={classes.houveGrid}> 
                        <TableCell > {embalagem.id} </TableCell>
                        <TableCell >  {embalagem.descricao} </TableCell>
                        <TableCell >  {embalagem.unidade} </TableCell>
                        <TableCell >  {embalagem.fator} </TableCell>                        
          
                        <TableCell >
                          <IconButton className={classes.icone}
                            size="small"
                            onClick={() => abrirCadastroEmbalagem(embalagem)}
                          >
                            <Edit className={classes.acaoBotao} />
                          </IconButton>
                          <IconButton className={classes.icone}
                              size="small"
                              onClick={() => {                          
                              setSelecionaEmbalagem(embalagem);
                              setConfirmModalOpen(true);
                            }}
                          >
                            <DeleteOutline className={classes.acaoBotao} />
                          </IconButton>
                        </TableCell>
          
                      </TableRow> 
                
              ))}
              {loading && <TableRowSkeleton columns={5} />}
              </>
            </TableBody> 

              {totalCount === 0 && !loading && (
                <caption>{Configuracao.LISTAGEM_VAZIA}</caption>
              )}            
            
              <TableFooter>

                {loading && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <LinearProgress variant='indeterminate' />
                    </TableCell>
                  </TableRow>
                )}             
              
                {(totalCount > 0 && totalCount > Configuracao.LIMITE_DE_LINHAS) && (
                  <TableRow>
                    <TableCell colSpan={10}>
                      <Pagination
                        page={page}
                        count={Math.ceil(totalCount / Configuracao.LIMITE_DE_LINHAS)}
                        onChange={(_, newPage) => setPage(newPage)}                    
                      />
                    </TableCell>
                  </TableRow>
                )}
                
              </TableFooter>

            </Table>
        </TableContainer> 
              
      </Paper>
      
    </MainContainer>       
  );
};

export default Embalagem;            