export const formatarDataHora = (dateStr) => {
    const date = new Date(dateStr);

    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); 
    const year = date.getUTCFullYear();

    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');

    const formattedDate = `${day}/${month}/${year} às ${hours}:${minutes}`;

    return formattedDate;
};