import React, { useState, useEffect } from "react";
import { Dialog, Container, useMediaQuery } from "@mui/material";
import "./style.css";
import api from "../../services/api";

import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import DialogModal from "../DialogModal";

const ImpressaoVisualizacaoDocumento = ({ open, onClose, nota }) => {
	const [pdfUrl, setPdfUrl] = useState(undefined);
	const isSmallScreen = useMediaQuery('(max-width:825px)');
	const [dialogModal, setDialogModal] = useState({open: false, keyword: "", title: "", description: "", buttons: [], result: ""});
	
	useEffect(() => {
		setTimeout(() => {
			if (!isSmallScreen && open) {
				handleVisualizarPDF(true);
			}
		}, 1000);
		
	}, [open]);

	const handleClose = () => {
			setTimeout(() => {
					setPdfUrl(undefined);	
					onClose();
			}, 200);
	};

	const handleVisualizarPDF = async (openOnly = false) => {
		try {
			if (pdfUrl === undefined ) {
				const { data: pdfFile } = await api.get(`/v1/notas/${nota.id}/pdf/`, { responseType: 'blob', encoding: null, ContentType: "application/pdf" });
				const pdf = new File([pdfFile], nota.chave + ".pdf", {type: "application/pdf"});
				const pdfUrl = URL.createObjectURL(pdf, { type: 'application/pdf' });
				setPdfUrl(pdfUrl);
			} else if (!openOnly){
				setPdfUrl(undefined);	
			}
			
		} catch (error) {
			console.log(error.message);
		}
	};

	const handleImprimir = async () => {
		try {
			const { data: pdfFile } = await api.get(`/v1/notas/${nota.id}/pdf/`, { responseType: 'blob', encoding: null, ContentType: "application/pdf" });
			const pdf = new File([pdfFile], nota.chave + ".pdf", {type: "application/pdf"});
			const pdfUrl = URL.createObjectURL(pdf, { type: 'application/pdf' });
			const newWindow = window.open(pdfUrl);

			newWindow.onload = () => {
				newWindow.print();
			};
		} catch (error) {
			setDialogModal({...dialogModal, keyword: "compartilhar", open: true, title: "Erro ao imprimir", description: `Impressão indisponível no momento. ${error.message}`, buttons: ["OK"]});
		}
	};       

	const handleCompartilhar = async () => {	
		try {
			const { data: pdfFile } = await api.get(`/v1/notas/${nota.id}/pdf/`, { responseType: 'blob', encoding: null, ContentType: "application/pdf" });
			const pdf = new File([pdfFile], nota.chave + ".pdf", {type: "application/pdf"});

			const { data: empresa } = await api.get(`/v1/empresas/${nota.empresaId}`);
			const textoEnvio = "Olá, você esta recebendo a Nota Fiscal da empresa "+(empresa?.nomeFantasia ? empresa?.nomeFantasia : empresa?.razaoSocial)+".\nEnviado por notacerta.com.br"
			await navigator.share({
				title: textoEnvio,
				text: textoEnvio,
				files: [pdf],
			});
		} catch (error) {
			setDialogModal({...dialogModal, keyword: "compartilhar", open: true, title: "Erro ao compartilhar", description: `Compartilhamento indisponível no momento. ${error.message}`, buttons: ["OK"]});
		}
	};	

	const handleCloseDialogModal = (result) => {
		setDialogModal({...dialogModal, keyword: "", open: false, result: result});
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth={'true'}
			maxHeight={true}
			fullScreen={false}
			PaperProps={{
				style: {
						position: "fixed",
						bottom: 10,
						maxHeight: isSmallScreen ? '100vh' : '90vh',
						minHeight: '100px',
						maxWidth: '100vh',
						minWidth: isSmallScreen ? '350px' : "1000px",
				},
			}}
		>
			<DialogModal
				title={dialogModal.title}
				open={dialogModal.open}
				onClose={handleCloseDialogModal}  
				description={dialogModal.description}   
				buttons={dialogModal.buttons}      
				>				
			</DialogModal>
			<Container class={isSmallScreen ? "containerMainImpressaoVisualizacao container-mobile" : "containerMainImpressaoVisualizacao"} 
				style={{height: pdfUrl ? "100vh": "10vh"}} 
			>      
				{pdfUrl && (
					<Container class="containerPdf">
							<iframe
								src={pdfUrl}
								title="PDF Viewer"
								width="100%"
								height="100%"
								style={{ border: 'none' }}
							/>
					</Container>
				)} 
		
				{/* Botoes opcoes */}
				<Container class="containerImpressaoVisualizacao"
					style={{height: pdfUrl ? "10vh": "10vh"}} 	
				>
					{!isSmallScreen && <Container class="iconDescricaoImpressao"  
						style={{fontSize: isSmallScreen && 12}}
						onClick={() => {handleImprimir()}}
					>          
						<PrintOutlinedIcon style={{fontSize: isSmallScreen ? 20: 25}}/>	
						Imprimir			
					</Container>}       

						<Container class="iconDescricaoImpressao"  
								style={{fontSize: isSmallScreen && 12}}
								onClick={() => {handleCompartilhar()}}                    
								>   
								<ShareOutlinedIcon style={{fontSize: isSmallScreen ? 20: 25}}/>
								Compartilhar          
						</Container>

						<Container class="iconDescricaoImpressao"  
							style={{fontSize: isSmallScreen && 12}}
							onClick={() => {if (isSmallScreen) {handleImprimir()} else {(handleVisualizarPDF())}}}										
							> 
							<PageviewOutlinedIcon style={{fontSize: isSmallScreen ? 20: 25}}/>
							Visualizar          
						</Container>

						<Container class="iconDescricaoImpressao"  
							style={{fontSize: isSmallScreen && 12}}  
							onClick={() => {handleClose()}}
							>  
							<ExitToAppOutlinedIcon style={{fontSize: isSmallScreen ? 20: 25}}/>
							Fechar          
						</Container>
				</Container>

			</Container>
		</Dialog>
	);
};

export default ImpressaoVisualizacaoDocumento;
