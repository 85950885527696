import React from "react";
import { createRoot } from 'react-dom/client';
import { CssBaseline } from "@mui/material";

import App from "./App";

const root = createRoot(document.getElementById('root'));

root.render(
	<CssBaseline>
		<App />
	</CssBaseline>	
);