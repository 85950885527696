import api from "../../../../services/api";

export const buscarEmpresa = async() => {
	let empresa

	try {
		let empresaResponse
		empresaResponse = await api.get("/v1/empresas")
		const primeiraEmpresa = empresaResponse.data.empresas[0]
	
		empresaResponse = await api.get(`/v1/empresas/${primeiraEmpresa.id}`)		
		empresa = empresaResponse.data
	} catch (error) {		
    throw error; 		
	}	

  return empresa;
};


