import React from "react";
import { Button, Dialog } from "@mui/material";
import { Container, useMediaQuery } from "@mui/system";
import "./style.css"

const FlexMessage = ({open, onClose, title, message, tipo}) => {
  const isSmallScreen = useMediaQuery('(max-width:825px)'); 

  const handleClose = () => {        
    onClose();   
  }

  return (  
    <Dialog
        open={open}   
        onClose={handleClose}     
        fullWidth={true}
        maxHeight={true}          
        PaperProps={ {
          style: {
            borderRadius: 10,
            height: "35%",
            width: isSmallScreen ? "100%" : "70%",
            maxHeight: isSmallScreen ? "70vh" : "70vh", 
            minHeight: isSmallScreen ? "400px" : "200px",
            maxWidth: isSmallScreen ? "50vh" : "50vh",
            minWidth: isSmallScreen ? "350px" : "600px"
          },
        }}
        onKeyDown={(event) => {
          if (open) {
            if (event.key === 'Enter') {
              document.getElementById('enterKey').click();
            }
          }
        }}
    > 
      <Container class="mainf">

        <div class="icone"> 
          <div className="demo1">  
            { ((tipo === "sucess") && (

              <div className="ui-success">
                <svg viewBox="0 0 87 87" version="1.1" xmlns="http://www.w3.org/2000/svg" 
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g id="Group-3" transform="translate(2.000000, 2.000000)">
                        <circle id="Oval-2" stroke="rgba(165, 220, 134, 0.2)" strokeWidth="4" cx="41.5" cy="41.5" r="41.5"></circle>
                        <circle className="ui-success-circle" id="Oval-2" stroke="#A5DC86" strokeWidth="4" cx="41.5" cy="41.5" r="41.5"></circle>
                        <polyline className="ui-success-path" id="Path-2" stroke="#A5DC86" strokeWidth="4" points="19 38.8036813 31.1020744 54.8046875 63.299221 28"></polyline>
                      </g>
                    </g>
                  </svg>
              </div>
            ))
            ||
            ((tipo === 'error') && (
              
                <div className="ui-error">
                  <svg viewBox="0 0 87 87" version="1.1" xmlns="http://www.w3.org/2000/svg" 
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g id="Group-2" transform="translate(2.000000, 2.000000)">
                        <circle id="Oval-2" stroke="rgba(252, 191, 191, .5)" strokeWidth="4" cx="41.5" cy="41.5" r="41.5"></circle>
                        <circle className="ui-error-circle" stroke="#E97777" strokeWidth="4" cx="41.5" cy="41.5" r="41.5"></circle>
                        
                        <path className="ui-error-line1" d="M22.244224,22 L60.4279902,60.1837662" id="Line" stroke="#E97777" strokeWidth="3" strokeLinecap="round"></path>
                        <path className="ui-error-line2" d="M60.755776,21 L23.244224,59.8443492" id="Line" stroke="#E97777" strokeWidth="3" strokeLinecap="round"></path>
                      </g>
                    </g>
                  </svg>
                </div> 
            ))
            }  
          </div>
          
        </div>

        <div class="mensagem"> 
          <div class="mensagemTitle"> {title} </div>          
          <div class="mensagemCorpo">{message}</div>
        </div>

        <div class="botoes"> 
          <Button
            id='enterKey'
            tabIndex="-1"
            color="primary"
            variant="contained"
            onClick={handleClose}                             
            style={{width: isSmallScreen ? "95%" : "95%", height: 50, borderRadius: 5}}
          >
            OK
          </Button>

        </div>
      </Container>
    </Dialog>
  )
}

export default FlexMessage;