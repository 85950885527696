import React from "react";
import Typography from '@mui/material/Typography';

import { Configuracao } from "../../configuracao";

export default function Title(props) {
	if (props.visibility) {
		return (
			<Typography 
			  justifyContent= "center"
			  display= "flex"  		
			  marginTop = "10px"
			  alignItems= "center"
			  variant= "h6"
			  fontWeight= "bold"
			  visibility={props.visibility}
			  color = {Configuracao.COR_SECUNDARY}
			  gutterBottom>
			  {props.children}
			</Typography>
		);
	} else {
		return (<></>);
	}
}