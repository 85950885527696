import { buscarCliente } from "./funcoes/buscarCliente";

export const gerarDest = (operacaoOpcao, destinatario) => {
	let dest = undefined

	let cnpj = destinatario?.inscricao?.length === 14 ? destinatario?.inscricao : undefined
	let cpf = destinatario?.inscricao?.length < 14 && destinatario?.inscricao ? destinatario?.inscricao : undefined

	if (cnpj || cpf) {
		dest = { 
			id: operacaoOpcao.alterar ? destinatario?.id : undefined,
			clienteId: destinatario?.clienteId,
			CNPJ: cnpj,
			CPF: cpf,
			xNome: destinatario?.nome,
			enderDest: destinatario?.endereco?.logradouro ? {
					id: undefined,
					xLgr: destinatario?.endereco?.logradouro,
					nro: destinatario?.endereco?.numero,
					xCpl: destinatario?.endereco?.complemento,
					xBairro: destinatario?.endereco?.bairro,				
					
					cMun: destinatario?.endereco?.codigoIbge,
					xMun: destinatario?.endereco?.municipio,
					UF: destinatario?.endereco?.uf,
					
					CEP: destinatario?.endereco?.cep,
					cPais: "1058",
					xPais: "Brasil",
					fone: destinatario?.endereco?.fone
			} : undefined,		
			IE: destinatario?.endereco?.logradouro ? destinatario?.inscricaoEstadual.toUpperCase() === "ISENTO" ? null : destinatario?.inscricaoEstadual : undefined,   
			indIEDest: destinatario?.endereco?.logradouro ? (destinatario?.inscricaoEstadual?.length > 0 && destinatario?.inscricaoEstadual.toUpperCase() !== "ISENTO" ? "1" : "9") : undefined, 
			ISUF: undefined,      
			IM: undefined,
			email: destinatario?.email,
		}	
	}

  return dest
}