import React, { useState, useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { AuthProvider, AuthContext } from "../context/Auth/AuthContext";
import Embalagem from "../pages/Embalagem";
import Empresa from "../pages/Empresa";
import LoggedInLayout from "../layout/index";
import Login from "../pages/Login/";
import Loading from "../pages/Loading"
import Main from "../pages/Main";
import Page404 from "../pages/Page404/";
import Produto from "../pages/Produto/";
import Users from "../pages/Users";
import Cliente from "../pages/Cliente";
import EmpresaConfig from "../pages/EmpresaConfig";
import Nota from "../pages/Vendas";
import Wizard from "../pages/Wizard";

const Rotas = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  
  const handleToggleMenu = () => {
    setDrawerOpen((previousValue) => !previousValue);
  };

  const PrivateRoute = ({ page }) => {
    const { isAuth } = useContext(AuthContext);
    return !isAuth ? <Navigate to="/login" /> : page;
  };

  const LoginRoute = ({ page }) => {
    const { isAuth } = useContext(AuthContext);
    return isAuth ? <Navigate to="/" /> : page;
  };

  const LoadingRoute = ({ page }) => {
    const { isAuth } = useContext(AuthContext);
    return isAuth ? <Navigate to ="/loading" /> : page;
  };


  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route index path="/login" element={<LoginRoute page={<Login />} />}/>
          <Route path="/loading" element={<LoadingRoute page={<Loading />} />}/>

          <Route element={<LoggedInLayout drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} handleToggleMenu={handleToggleMenu} />}>
            <Route path="/" element={<PrivateRoute page={<Main handleToggleMenu={handleToggleMenu}/>} />} />
            <Route path="/wizard" element={<PrivateRoute page={<Wizard />} />} />
            <Route path="/users" element={<PrivateRoute page={<Users />} />} />
            <Route path="/embalagens" element={<PrivateRoute page={<Embalagem />} />} />
            <Route path="/empresas" element={<PrivateRoute page={<Empresa />} />} />
            <Route path="/empresaConfig" element={<PrivateRoute page={<EmpresaConfig />} />} />
            <Route path="/produtos" element={<PrivateRoute page={<Produto />} />} />
            <Route path="/clientes" element={<PrivateRoute page={<Cliente />} />} />
            <Route path="/notas" element={<PrivateRoute page={<Nota/>}/>} />
          </Route>

          <Route path="*" element={<Page404 />}/>
        </Routes>
        <ToastContainer autoClose={3000} />
      </AuthProvider>
    </BrowserRouter>
  );
};
export default Rotas;
